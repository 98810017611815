import React from 'react'
import Layout from '../components/Layout'
import {Link} from 'gatsby'

export default class Page404 extends React.Component {
  render() {
    
  return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
            <div
                className="full-width-image-container margin-top-0"
                style={{
                  backgroundImage: `url('/img/oops3.jpg')`,
                  backgroundPosition: "top"  
                }}
              >
                <div style={{
                  display: 'flex',
                  height: '150px',
                  lineHeight: '1',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  flexDirection: 'column' }}
                >
                  <h3
                    className="has-text-weight-bold is-size-3"
                    style={{
                      boxShadow: '0.5rem 0 0 #406eb7, -0.5rem 0 0 #406eb7',
                      backgroundColor: '#406eb7',
                      color: 'white',
                      padding: '1rem',
                      marginBottom:0
                    }}
                  >
                    404 - Oops! This is embarrassing.
                  </h3>
                
                  <h3
                    className="has-text-weight-bold is-size-3"
                    style={{
                      boxShadow: '0.5rem 0 0 #406eb7, -0.5rem 0 0 #406eb7',
                      backgroundColor: '#406eb7',
                      color: 'white',
                      padding: '1rem',
                      marginTop:".5rem"
                    }}
                  >
                  Please try our <Link to="/" style={{color:"orange"}}>home page</Link>. It's much nicer.
                  </h3>
                </div>
                
              </div>
            </div>
              
            </div>
        </section>
      </Layout>
    )
  }
}
